//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #F82652; // #F71C5B; 
$info: #112132;
// 112132
// #f8f8f8

// $primary: #039be5;

$menu-expanded-width: 280px !default;
