/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// $red: #ea5455 !default; //$danger
// $body-color: #6e6b7b !default;

// @use "@styles/base/core/mixins/main-menu-mixin" as *;

// // Main menu base
// //==============

// .menu-light {
//   @include menu-theme($body-color, $red);
// }

.card {
  .card {
    box-shadow: none;
  }
}

.sz-border-end {
  border-right: 1px solid rgb(235, 233, 241);
}

.sz-header {
  padding: 1rem 1.5rem;
  background-color: #112132;
}

.sz-map-button {
  width: 30px;
  height: 30px;
  background-color: whitesmoke;
  border-radius: 10%;
  border: 1px solid;
}
.eyeHide {
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
}
